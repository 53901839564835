@import 'themes-vars.module';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Georama:wdth,wght@62.5..150,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wdth,wght@25..151,200..800&display=swap');

body,
html {
  font-family: DM sans, sans-serif;

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}
