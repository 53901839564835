.ag-header-cell-label .ag-header-cell-text {
    white-space: pre-wrap !important;
    justify-content: center !important;
    // width: 100% !important;
}
.ag-header-cell-label {
    justify-content: center !important;
    font-size: 12px !important;
    // padding-top: 16px;
    // width: 100% !important;;
    // padding:0 !important;
 }

 .ag-header-group-cell-label {
    justify-content: center !important;
    font-size: 14px !important;
    // text-align: center;
    // height: 100%;
    // padding:0 !important;
 }
 .ag-header-group-cell {
    justify-content: center !important;
    font-size: 14px !important;
    // text-align: center;
    // height: 100%;
    // padding:0 !important;
 }
 
 .ag-header-row .ag-header-row-column-group {
    background-color: "#123d7a" !important;
 }

 .ag-cell{
    justify-content: center !important;
    display: inline-flex !important;
    line-height: 20px !important;
 }

.ag-theme-alpine-dark .ag-header-cell,
.ag-theme-alpine-dark .ag-header-group-cell,
.ag-theme-alpine-dark .ag-ltr .ag-cell {
    border-right: 0.5px solid #D9DDDF !important;
    border-bottom: 0.5px solid #D9DDDF !important;
    }

.ag-theme-alpine-dark{
    --ag-background-color: #022D5A !important;
    --ag-header-background-color: #2c5a9d !important;
    --ag-border-color:#ffffff !important;
    --ag-odd-row-background-color:#022D5A !important;
}

.ant-picker-input > input{
    color: #fff !important; 
    font-size: 20px;  
  }
  
.ant-picker-input input::placeholder {
    color: #fff !important;
    opacity: 0.8 !important;
}
  
.ant-picker-suffix {
    color: #fff !important;
}  

.ant-picker-clear {
    color: #fff !important;
}  

.ant-picker-now-btn {
    display: none !important;
  }
  
