.tooltip {
  position: absolute;
  text-align: center;
  width: auto;					
  height: auto;	
  padding: 10px 10px 10px 10px;
  font: 12px sans-serif;		
  background-color:  #0a0a0a;	
  border: 1px;		
  border-radius: 8px;			
  pointer-events: none;
}

.line {
  fill:none;
  stroke:white;
  width:2;
}

.tooltipCrossHair {	
  position: absolute;
  background-color:  #475569;
  color:#ffffff;	
  padding: 2px;
  font: 12px sans-serif;
  border: 1px;		
  border-radius: 2px;	
  border: 1px solid gray;	
  z-index: 99999;
  }

.tooltipLiveMarker {	
  position: absolute;
  background-color:  #4d81c9;
  color:#ffffff;	
  padding: 2px;
  font: 12px sans-serif;
  border: 1px;		
  border-radius: 2px;	
  text-align: center;
  border: 1px solid gray;
  // width:60px;	
}
.tooltipReplayLiveMarker {	
  position: absolute;
  background-color:  #475569;
  color:#ffffff;	
  padding: 2px;
  font: 12px sans-serif;
  border: 1px;		
  border-radius: 2px;	
  border: 1px solid gray;	
  text-align: center;
  z-index: 99999;
  }

.tooltipLiveCloseVwap {	
  position: absolute;
  background-color:  #E0F6FC;
  color:#000000;	
  padding: 2px;
  font: 12px sans-serif;
  border: 1px;		
  border-radius: 2px;	
  text-align: center;
  border: 1px solid gray;
  // width:60px;	
}

.tooltipFrozenLine {	
  position: absolute;
  background-color:  #BDF797;
  color:#000;	
  padding: 2px;
  font: 12px sans-serif;
  border: 1px;		
  border-radius: 2px;	
  border: 1px solid gray;	
  z-index: 99999;
  }
  
/* canvas styles  */
.chart-container {
  /* margin: auto; */
  position: relative;
  display:block;
  width: calc(100% - 51px);
  height: calc(100% - 32px);
  background-color:  #022D5A;
  color: #d8f6fa;
}

.chart-container-new {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.chart-container-new1 {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.chart-container-new2 {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.chart-container-new3 {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.chart-container-new4 {
  /* margin: auto; */
  position: fixed;
  overflow-y: auto;
  background-color:  #022D5A;
  color: #d8f6fa;
  border-right: 2px solid #163569;

}
.chart-container-new5 {
  /* margin: auto; */
  position: fixed;
  overflow-y: auto;
  background-color:  #022D5A;
  color: #d8f6fa;
  border-right: 2px solid #163569;

}

.svg-plot, .canvas-plot {
  position: absolute;
}

.canvas-tooltip {
position: absolute;
visibility: hidden;
}

.tools {
position: absolute;
  background-color:  "transparent";
  color:#ffffff;	
  padding: 4px;
  font: 12px sans-serif;
  // border: 1px;		
  // border-radius: 2px;	
  // border: 1px solid gray;
  z-index: 9;
  /* top:calc(100vh -120px); */
  left: calc(50% - 60px);
  cursor: pointer;
}
.buttonTool{
background-color:  #475569;
color:#ffffff;	
font: 12px sans-serif;
cursor: pointer;
border: none;
margin-right: 5px;
height: 20px;
border-radius: 4px;
}

.context-menu {
z-index: 9999;
width: 180px;
height: auto;
position: fixed;
background-color: #1e293b;
border: 2px solid #475569;
padding: 2px 0;
border-radius: 8px;
list-style-type: none;

li {
  font-size: 12px;
  color: #ffffff;
  padding: 5px 10px;
  line-height: 18px;
  white-space: nowrap;      /* Prevent text from wrapping to the next line */
  overflow: hidden;         /* Hide any content that overflows the box */
  text-overflow: ellipsis;  /* Display an ellipsis (...) when text overflows */
  cursor: pointer;
  &:hover {
    background: #475569;
  }
}

span {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
}
}

.context-menu-krl {
z-index: 9999;
// width: 340px;
height: auto;
position: fixed;
background-color: #032D5A;
border: 2px solid #475569;
padding: 2px 0;
border-radius: 8px;
list-style-type: none;

li {
  font-size: 12px;
  color: #ffffff;
  padding: 5px 10px;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    background: #475569;
  }
}

span {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
}
}

.hr-menu {
border-top: 0.5px solid #666060;
border-bottom: none;
margin:0px
}

.loading {
position: absolute;
  background-color:  "transparent";
  color:#ddd;	
  padding: 4px;
  font: 12px sans-serif;
  z-index: 999999;
  top:50px;
  left: calc(50% - 60px);
  cursor: pointer;
}

.loading-replay {
  position: absolute;
    background-color:  "transparent";
    color:#ddd;	
    padding: 4px;
    font: 12px sans-serif;
    z-index: 999999;
    top:70px;
    left: calc(50% - 60px);
    cursor: pointer;
  }


.watermark {
position: absolute;
  background-color:  "transparent";
  color:#ffffff;	
  padding: 4px;
  font: 40px sans-serif;
  // z-index: 9;
  /* top:calc(100vh -120px); */
  left: calc(50% - 180px);
  opacity: 0.3;
  cursor: none;
}

.of_chart-container-new {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.of_chart-container-new1 {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.of_chart-container-new2 {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.of_chart-container-new3 {
/* margin: auto; */
position: fixed;
overflow-y: auto;
background-color:  #022D5A;
color: #d8f6fa;
border-right: 2px solid #163569;

}

.of_chart-container-new4 {
  /* margin: auto; */
  position: fixed;
  overflow-y: auto;
  background-color:  #022D5A;
  color: #d8f6fa;
  border-right: 2px solid #163569;
  
  }
  
  .of_chart-container-new5 {
  /* margin: auto; */
  position: fixed;
  overflow-y: auto;
  background-color:  #022D5A;
  color: #d8f6fa;
  border-right: 2px solid #163569;
  
  }